import { default as index6VIsPLY9QAMeta } from "/app/pages/[prefix]/[...slug]/index.vue?macro=true";
import { default as indexVqvd4CIKXXMeta } from "/app/layers/cart/pages/[prefix]/cart/index.vue?macro=true";
import { default as indexYXAC16ekWVMeta } from "/app/pages/[prefix]/index.vue?macro=true";
import { default as indexyWS05ccSCmMeta } from "/app/pages/[prefix]/loveitem-finder/index.vue?macro=true";
import { default as indexLqY1yKc8ftMeta } from "/app/layers/world-of-oris/pages/[prefix]/magazine-article/index.vue?macro=true";
import { default as indexJcjB1hcSo7Meta } from "/app/pages/[prefix]/product/[...fallback]/index.vue?macro=true";
import { default as _91sku_939Mnvbz2UMzMeta } from "/app/pages/[prefix]/product/[type]/[name]/[sku].vue?macro=true";
import { default as _91sku_93vWe0BujPBhMeta } from "/app/pages/[prefix]/product/strap/[name]/[sku].vue?macro=true";
import { default as _91sku_93IReSj16XByMeta } from "/app/pages/[prefix]/product/watch/[collection]/[watchName]/[sku].vue?macro=true";
import { default as indexv86JMrmc9XMeta } from "/app/pages/[prefix]/product/watch/[collection]/index.vue?macro=true";
import { default as indexuSHsnVm7rCMeta } from "/app/pages/[prefix]/storefinder/index.vue?macro=true";
import { default as indexFxqVLfjQITMeta } from "/app/pages/[prefix]/strap-finder/index.vue?macro=true";
import { default as indexKfG8wXq5DMMeta } from "/app/pages/[prefix]/styleguide/buttons/index.vue?macro=true";
import { default as indexlEOZkbFlVRMeta } from "/app/pages/[prefix]/styleguide/colors/index.vue?macro=true";
import { default as CheckboxG7IGjieNi3Meta } from "/app/pages/[prefix]/styleguide/inputs/Checkbox.vue?macro=true";
import { default as Dropdown9WEAg1d529Meta } from "/app/pages/[prefix]/styleguide/inputs/Dropdown.vue?macro=true";
import { default as indexrFDamEdMdxMeta } from "/app/pages/[prefix]/styleguide/inputs/index.vue?macro=true";
import { default as RadiobMGFWZjYf0Meta } from "/app/pages/[prefix]/styleguide/inputs/Radio.vue?macro=true";
import { default as RadioGroupofRWo6Pw3sMeta } from "/app/pages/[prefix]/styleguide/inputs/RadioGroup.vue?macro=true";
import { default as TextInputlVhcP8UC0rMeta } from "/app/pages/[prefix]/styleguide/inputs/TextInput.vue?macro=true";
import { default as indexjBOXlxpl7wMeta } from "/app/pages/[prefix]/styleguide/link/index.vue?macro=true";
import { default as gallery_45gridwyIS2uUO5FMeta } from "/app/pages/[prefix]/styleguide/paragraph/gallery-grid.vue?macro=true";
import { default as text_45imager5iCSoWyLGMeta } from "/app/pages/[prefix]/styleguide/paragraph/text-image.vue?macro=true";
import { default as textdu1tVJD9SCMeta } from "/app/pages/[prefix]/styleguide/paragraph/text.vue?macro=true";
import { default as videozLXWbayIQ7Meta } from "/app/pages/[prefix]/styleguide/paragraph/video.vue?macro=true";
import { default as indexquQZZuoFwHMeta } from "/app/pages/[prefix]/styleguide/slider/index.vue?macro=true";
import { default as indexePVO12GBoiMeta } from "/app/pages/[prefix]/styleguide/variations/index.vue?macro=true";
import { default as indexJekZXUJhrPMeta } from "/app/pages/[prefix]/watch-finder/index.vue?macro=true";
import { default as indextgVPeD9Gb9Meta } from "/app/layers/world-of-oris/pages/[prefix]/worldoforis/[category]/index.vue?macro=true";
import { default as indexaMi1GQm58TMeta } from "/app/layers/world-of-oris/pages/[prefix]/worldoforis/index.vue?macro=true";
import { default as worldoforisMGc3uR3z0MMeta } from "/app/layers/world-of-oris/pages/[prefix]/worldoforis.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:prefix()/:slug(.*)*",
    component: () => import("/app/pages/[prefix]/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/:prefix()/cart",
    component: () => import("/app/layers/cart/pages/[prefix]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/:prefix()",
    component: () => import("/app/pages/[prefix]/index.vue").then(m => m.default || m)
  },
  {
    name: "loveitem-finder",
    path: "/:prefix()/loveitem-finder",
    meta: indexyWS05ccSCmMeta || {},
    component: () => import("/app/pages/[prefix]/loveitem-finder/index.vue").then(m => m.default || m)
  },
  {
    name: "worldoforis-article",
    path: "/:prefix/worldoforis/:category/:slug(.*)*",
    meta: indexLqY1yKc8ftMeta || {},
    component: () => import("/app/layers/world-of-oris/pages/[prefix]/magazine-article/index.vue").then(m => m.default || m)
  },
  {
    name: "prefix-product-fallback",
    path: "/:prefix()/product/:fallback(.*)*",
    component: () => import("/app/pages/[prefix]/product/[...fallback]/index.vue").then(m => m.default || m)
  },
  {
    name: "product-detail-generic",
    path: "/:prefix/product/:type(gift|giveaway|tool|spare-part)/:name/:sku",
    component: () => import("/app/pages/[prefix]/product/[type]/[name]/[sku].vue").then(m => m.default || m)
  },
  {
    name: "product-detail-strap",
    path: "/:prefix()/product/strap/:name()/:sku()",
    component: () => import("/app/pages/[prefix]/product/strap/[name]/[sku].vue").then(m => m.default || m)
  },
  {
    name: "product-detail-watch",
    path: "/:prefix()/product/watch/:collection()/:watchName()/:sku()",
    component: () => import("/app/pages/[prefix]/product/watch/[collection]/[watchName]/[sku].vue").then(m => m.default || m)
  },
  {
    name: "prefix-product-watch-collection",
    path: "/:prefix()/product/watch/:collection()",
    component: () => import("/app/pages/[prefix]/product/watch/[collection]/index.vue").then(m => m.default || m)
  },
  {
    name: "storefinder",
    path: "/:prefix/storefinder/:id?",
    meta: indexuSHsnVm7rCMeta || {},
    component: () => import("/app/pages/[prefix]/storefinder/index.vue").then(m => m.default || m)
  },
  {
    name: "strap-finder",
    path: "/:prefix()/strap-finder",
    meta: indexFxqVLfjQITMeta || {},
    component: () => import("/app/pages/[prefix]/strap-finder/index.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-buttons",
    path: "/:prefix()/styleguide/buttons",
    component: () => import("/app/pages/[prefix]/styleguide/buttons/index.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-colors",
    path: "/:prefix()/styleguide/colors",
    component: () => import("/app/pages/[prefix]/styleguide/colors/index.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-inputs-Checkbox",
    path: "/:prefix()/styleguide/inputs/Checkbox",
    component: () => import("/app/pages/[prefix]/styleguide/inputs/Checkbox.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-inputs-Dropdown",
    path: "/:prefix()/styleguide/inputs/Dropdown",
    component: () => import("/app/pages/[prefix]/styleguide/inputs/Dropdown.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-inputs",
    path: "/:prefix()/styleguide/inputs",
    component: () => import("/app/pages/[prefix]/styleguide/inputs/index.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-inputs-Radio",
    path: "/:prefix()/styleguide/inputs/Radio",
    component: () => import("/app/pages/[prefix]/styleguide/inputs/Radio.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-inputs-RadioGroup",
    path: "/:prefix()/styleguide/inputs/RadioGroup",
    component: () => import("/app/pages/[prefix]/styleguide/inputs/RadioGroup.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-inputs-TextInput",
    path: "/:prefix()/styleguide/inputs/TextInput",
    component: () => import("/app/pages/[prefix]/styleguide/inputs/TextInput.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-link",
    path: "/:prefix()/styleguide/link",
    component: () => import("/app/pages/[prefix]/styleguide/link/index.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-paragraph-gallery-grid",
    path: "/:prefix()/styleguide/paragraph/gallery-grid",
    component: () => import("/app/pages/[prefix]/styleguide/paragraph/gallery-grid.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-paragraph-text-image",
    path: "/:prefix()/styleguide/paragraph/text-image",
    component: () => import("/app/pages/[prefix]/styleguide/paragraph/text-image.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-paragraph-text",
    path: "/:prefix()/styleguide/paragraph/text",
    component: () => import("/app/pages/[prefix]/styleguide/paragraph/text.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-paragraph-video",
    path: "/:prefix()/styleguide/paragraph/video",
    component: () => import("/app/pages/[prefix]/styleguide/paragraph/video.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-slider",
    path: "/:prefix()/styleguide/slider",
    component: () => import("/app/pages/[prefix]/styleguide/slider/index.vue").then(m => m.default || m)
  },
  {
    name: "prefix-styleguide-variations",
    path: "/:prefix()/styleguide/variations",
    component: () => import("/app/pages/[prefix]/styleguide/variations/index.vue").then(m => m.default || m)
  },
  {
    name: "watch-finder",
    path: "/:prefix()/watch-finder",
    meta: indexJekZXUJhrPMeta || {},
    component: () => import("/app/pages/[prefix]/watch-finder/index.vue").then(m => m.default || m)
  },
  {
    name: worldoforisMGc3uR3z0MMeta?.name,
    path: "/:prefix()/worldoforis",
    component: () => import("/app/layers/world-of-oris/pages/[prefix]/worldoforis.vue").then(m => m.default || m),
    children: [
  {
    name: "prefix-worldoforis-category",
    path: ":category()",
    component: () => import("/app/layers/world-of-oris/pages/[prefix]/worldoforis/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "worldoforis-all",
    path: "",
    component: () => import("/app/layers/world-of-oris/pages/[prefix]/worldoforis/index.vue").then(m => m.default || m)
  }
]
  }
]