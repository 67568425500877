<template>
  <DrupalLink
    v-if="to"
    :to="to"
    class="collection group relative block bg-white bg-[length:100%_100%] bg-repeat-x uppercase text-white focus-visible:outline-2 focus-visible:outline-offset-4 md:text-base"
    :class="{ 'bg-gradient-to-b from-grey-light-01 to-grey-dark-02': !style }"
    :style="style"
  >
    <div
      class="relative z-20 flex h-full flex-col px-12 pb-12 transition duration-300 group-hover:bg-grey-dark-01/10 group-focus-visible:bg-grey-dark-01/10 md:px-24 md:pb-16"
    >
      <div
        class="mx-auto my-[12.5%] flex aspect-[97/120] w-[84%] items-center justify-center bg-white p-[8%] md:aspect-[194/248]"
      >
        <MediaImage
          v-if="watchImage"
          v-bind="watchImage"
          :image-style="imageStyle"
          class="collection-image relative h-full w-full transition duration-300 ease-swing group-hover:scale-90"
          img-class="w-full h-full object-contain absolute top-0 left-0"
        />
      </div>
      <h3 class="md:typo-lead leading-tight md:mb-2">
        {{ label }}
      </h3>
      <span class="text-xs">{{ countLabel }}</span>
    </div>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type { WatchCollectionTeaserFragment } from '#graphql-operations'

const { $texts } = useNuxtApp()

const props = defineProps<{
  id?: string
  label?: string
  url?: WatchCollectionTeaserFragment['url']
  backgroundImage?: WatchCollectionTeaserFragment['backgroundImage']
  watchImage?: WatchCollectionTeaserFragment['watchImage']
  watchVariationsCount?: WatchCollectionTeaserFragment['watchVariationsCount']
}>()

// Cases where this element is used:
// a) Slider at the bottom of Collection pages https://oris.ch/en-US/product/watch/propilot-x
// b) Slider in the navigation
// c) Grid at top of https://oris.ch/en-US/product/watch
const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      // width: 122, // a
      // width: 92, // b
      width: 230, // c
    },
    md: {
      width: 250, // a
      // width: 103, // b
      // width: 160, // c
    },
    lg: {
      width: 250, // a
      // width: 135, // b
      // width: 135, // c
    },
    xl: {
      width: 250, // a
      // width: 180, // b
      // width: 150, // c
    },
    xxl: {
      width: 250, // a
      // width: 200, // b
      // width: 175, // c
    },
    // not increasing for xxxl
  },
})

const to = computed(() => props.url?.path)

const style = computed(() => {
  const backgroundImage = buildWatchCollectionBackground(props.backgroundImage)
  if (!backgroundImage) {
    return
  }
  return {
    backgroundImage: `url(${backgroundImage})`,
  }
})

const countLabel = computed(() => {
  const total = props.watchVariationsCount
  if (!total) {
    return $texts('modelCountZero', 'No models')
  }

  return total === 1
    ? $texts('modelCountSingular', '1 model')
    : $texts('modelCountPlural', '@count models').replace(
        '@count',
        total.toString(),
      )
})
</script>
