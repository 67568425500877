import { defineNuxtPlugin } from 'nuxt/app'
import type { FetchResponse } from 'ofetch'
import type { GraphqlResponse } from '#graphql-middleware-server-options-build'
import type { DrupalMessage } from '~/composables/useDrupalMessages'
import type { GraphqlMiddlewareResponseUnion } from '#build/nuxt-graphql-middleware'

/**
 * Try to extract the messages from a GraphQL query or mutation.
 */
function extractMessages(
  response:
    | FetchResponse<GraphqlResponse<GraphqlMiddlewareResponseUnion>>
    | undefined,
): DrupalMessage[] {
  if (
    response?._data &&
    'messengerMessages' in response._data.data &&
    response._data.data.messengerMessages &&
    Array.isArray(response._data.data.messengerMessages)
  ) {
    return response._data.data.messengerMessages.map((v) => {
      return {
        type: v.type,
        message: v.safe,
      }
    })
  }

  return []
}

/**
 * This is only called when performing a query or mutation from within the nuxt
 * app (e.g. not via custom server routes).
 */
export default defineNuxtPlugin({
  name: 'playground-state-plugin',
  dependsOn: ['nuxt-graphql-middleware-provide-state'],
  setup() {
    const state = useGraphqlState()
    if (!state) {
      return
    }

    const { messages } = useDrupalMessages()
    const language = useCurrentLanguage()
    const country = useCurrentCountry()
    const config = useRuntimeConfig()

    state.fetchOptions = {
      onResponse(result) {
        // Extract drupal messages from every GraphQL response.
        extractMessages(result.response).forEach((v) => {
          const exists = messages.value.find((m) => m.message === v.message)
          if (!exists) {
            messages.value.push(v)
          }
        })
      },
      onRequest({ options }) {
        try {
          if (!options.params) {
            options.params = {}
          }

          options.params.__language_context = language.value
          options.params.__country_context = country.value
          options.params.__hash = config.public.buildHash

          if (!options.headers) {
            options.headers = {}
          }
          options.headers = useRequestHeaders()
        } catch (e) {}
      },
    }
  },
})
