<template>
  <figure v-if="image && image.file?.rokka?.hash">
    <RokkaImage
      v-if="imageStyle"
      :config="imageStyle"
      :file="image.file"
      :alt="image.alt"
      :title="image.title"
      :img-class="imgClass"
      :loading="loading"
      :preload="preload"
      :hash="image.file.rokka.hash"
    />
    <img
      v-else
      :key="url?.path"
      :src="url?.path"
      :loading="loading"
      :class="imgClass"
    />
  </figure>
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaImageFragment } from '#graphql-operations'

const props = defineProps<{
  name?: string
  image?: MediaImageFragment['image']
  imageStyle?: DefineImageStyleConfig
  imgClass?: string
  url?: MediaImageFragment['url']

  /**
   * Eagerly load image.
   */
  eager?: boolean

  /**
   * Preload the image. When set, the images are reploaded using a <link> tag.
   */
  preload?: boolean
}>()

defineOptions({
  name: 'MediaImage',
})

const loading = computed(() => (props.eager ? 'eager' : 'lazy'))
</script>
