<template>
  <div class="bk-main-canvas">
    <ClientOnly v-if="drupalUser.canAccessToolbar && !isEditing">
      <AdminBar />
    </ClientOnly>

    <AppTop :is-editing="isEditing" class="w-full" />

    <div class="min-h-[60vh]">
      <ClientOnly>
        <DrupalMessages v-if="!isEditing" />
      </ClientOnly>

      <NuxtPage />
    </div>

    <AppBottom />
  </div>
</template>

<script setup lang="ts">
import { getLocaleFromPath } from '~/helpers/locale'

const drupalUser = await useDrupalUser()
const route = useRoute()
const language = useCurrentLanguage()
const config = useRuntimeConfig()
const currentLocale = getLocaleFromPath(globalThis.location?.pathname ?? '')

const AdminBar = defineAsyncComponent(
  () => import('./components/AdminBar/index.vue'),
)

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.canAccessToolbar,
)

watch(isEditing, (isEditing) => {
  const classList = document.querySelector('body')?.classList
  if (isEditing) {
    classList?.add('isEditing')
  } else {
    classList?.remove('isEditing')
  }
})

useHead({
  script: [
    {
      async: true,
      src: 'https://static.zdassets.com/ekr/snippet.js?key=d8457cff-ca13-439e-b314-04c5d3c02019',
      id: 'ze-snippet',
      onload: () => {
        window.zE('webWidget', 'setLocale', currentLocale.langcode ?? 'none')
      },
    },
  ],
})

if (process.browser) {
  // Wait for the user accepting tracking till we start with it
  // Coded with the help of https://docs.usercentrics.com/#/consent-mode?id=step-2-optional-use-the-usercentrics-cmp-events-to-signal-the-consent-status-via-the-consent-mode-api-for-custom-data-processing-services
  window.addEventListener('ucEvent', function (e) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const detail: any = 'detail' in e ? e.detail : undefined
    if ('event' in detail && detail.event === 'consent_status') {
      if (detail['Google Tag Manager']) {
        const gtm = useGtm()
        gtm?.enable(true)
        const { pushWaitForConsentEvents } = useAnalyticsTracking()
        pushWaitForConsentEvents()
      }
    }
  })

  // only add usercentrics script to the head after we registered the event listener.
  useHead({
    htmlAttrs: {
      lang: language,
    },
    script: [
      {
        async: true,
        src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
        id: 'usercentrics-cmp',
        'data-settings-id': 'qYn3eubyTQMI78',
        'data-version': config.public.isTesting ? 'preview' : undefined,
      },
    ],
  })

  // add function to test image styles
  // @ts-ignore
  window.showImageSizes = showImageSizes
}
</script>

<script lang="ts">
declare global {
  export interface Window {
    // https://developer.zendesk.com/api-reference/widget/core/#setlocale
    zE: (type: 'webWidget', command: 'setLocale', data: string) => void
  }
}
</script>

<style lang="postcss">
.page-enter-active,
.page-leave-active {
  @apply transition duration-150;
  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }
}

.page-enter-from,
.page-leave-to {
  @apply opacity-0;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition duration-300;
  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}

/* fix grid items sizing. https://stackoverflow.com/a/66779099/5935427 */
.grid > * {
  min-width: 0;
}

.bk-edit-indicator {
  /* larger than the 50'000 (--bk-z-index-base) from the App/Top/index.vue */
  z-index: 100000 !important;
}

.isEditing #launcher {
  /* Hide the Zendesk "Help" button when in blökkli editing mode. */
  display: none;
}

/* vite error overlay during development. */
vite-error-overlay {
  @apply z-vite-error-overlay;
}

/* The id of the iframe which renders the open chat bot */
#webWidget {
  @apply z-zendesk-chat-bot !important;
}
</style>
